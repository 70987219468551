import React, { useEffect, useRef } from 'react';
import quisommenouspng from '../Images/quisommenous.jpg';
import glyph1 from '../Images/glyph1.png';
import glyph2 from '../Images/glyph2.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

function Quisommesnous() {
    const imageRef = useRef(null);
    const titleRef = useRef(null);

    useEffect(() => {
        if (!imageRef.current || !titleRef.current) return; // Vérifie que les références sont définies

        // Animation de l'image en couleur et en noir et blanc au scroll
        gsap.fromTo(
            imageRef.current,
            { filter: 'grayscale(100%)' }, // Commence en noir et blanc
            {
                filter: 'grayscale(0%)', // Devient en couleur
                scrollTrigger: {
                    trigger: imageRef.current,
                    start: 'top bottom', // Début lorsque le haut de l'image atteint le bas de la fenêtre
                    end: 'bottom top',  // Fin lorsque le bas de l'image atteint le haut de la fenêtre
                    scrub: true, // L'animation suit le défilement
                },
            }
        );

        // Animation des textes
        const tlText = gsap.timeline({
            repeat: -1, // Répétition infinie
            yoyo: true, // Animation dans les deux sens
            defaults: { ease: "none" }
        });

        tlText.to(titleRef.current, {
            duration: 2,
            text: "En savoir plus sur l'ADPME",
            delay: 1,
            ease: "power2.out",
        });

    }, []);

    return (
        <>
            <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                <div>
                    <img src={quisommenouspng} alt="Qui sommes-nous" ref={imageRef} className='w-full h-auto' />
                </div>
                <div className='p-10 max-md:px-4 max-[1440px]:p-5'>
                    <div>
                        <span className="text-yellow-300 text-[26px] font-bold font-['Montserrat']">ADPME</span>
                    </div>
                    <div>
                        <span className="text-neutral-900 text-[32px] font-bold font-['Montserrat']" ref={titleRef}>Qui sommes-nous ?</span>
                    </div>
                    <div className='pt-6 max-[1440px]:pt-3'>
                        <p className="text-justify text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            L'ADPME, c'est l'assurance d'un accompagnement professionnel et personnalisé, pour vous permettre de réaliser votre rêve d'entrepreneur.
                            Nous sommes là pour vous aider à faire de votre entreprise un succès.
                        </p>
                        <br />
                        <p className="text-justify text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            L'Agence de Développement des Petites et Moyennes Entreprises a pour vocation de fédérer et de mettre en cohérence l'ensemble des interventions publiques en appui aux MPME avec une offre intégrée d'accompagnement, d'orientation et de financement des MPME.
                        </p>
                        <p className="mb-2 text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            Contactez-nous dès aujourd'hui pour en savoir plus.
                        </p>
                        <div className='max-md:flex max-md:flex-col max-md:gap-4 grid grid-cols-2 pt-6 max-[1440px]:pt-3'>
                            <div className='flex flex-row'>
                                <div>
                                    <img src={glyph1} alt="Glyph 1" />
                                </div>
                                <div>
                                    <p className="text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                                        AccompagnementOrientation,
                                        Développement, Appui,
                                        Succès
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-row'>
                                <div>
                                    <img src={glyph2} alt="Glyph 2" />
                                </div>
                                <div>
                                    <p className="text-neutral-900 text-[18px] font-normal bold font-['Montserrat']">
                                        Libérez le potentiel de votre entreprise dès aujourd'hui !
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Quisommesnous;
