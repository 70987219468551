import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DescBannniereApropos from './Components/DescBannniereApropos';
import Presentation from './Components/Presentation';
import Vision from './Components/Vision';
import Missions from './Components/Missions';
import Valeurs from './Components/Valeurs';
import Ambitions from './Components/Ambitions';
import Priorites from './Components/Priorites';

gsap.registerPlugin(ScrollTrigger);

function Quisommesnous(props) {
    const sectionsRef = useRef([]);

    useEffect(() => {
        sectionsRef.current.forEach((section, index) => {
            gsap.fromTo(
                section,
                { y: 100, opacity: 0 },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.5, // Durée prolongée pour une meilleure visibilité
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: section,
                        start: 'top 90%', // L'animation commence plus tôt pour une meilleure visibilité
                        end: 'top 60%', // Fin de l'animation plus tôt
                        toggleActions: 'play none none none',
                    }
                }
            );
        });
    }, []);

    return (
        <>
            <DescBannniereApropos />
            <div ref={(el) => sectionsRef.current[0] = el}><Presentation /></div>
            <div ref={(el) => sectionsRef.current[1] = el}><Vision /></div>
            <div ref={(el) => sectionsRef.current[2] = el}><Missions /></div>
            <div ref={(el) => sectionsRef.current[3] = el}><Valeurs /></div>
            <div ref={(el) => sectionsRef.current[4] = el}><Ambitions /></div>
            <div ref={(el) => sectionsRef.current[5] = el}><Priorites /></div>
        </>
    );
}

export default Quisommesnous;
