import React, { useState, useEffect } from 'react';
import logo from './Images/logoadpme_a.png';
import { Dropdown } from 'flowbite-react';
import Drop from './Images/down-arrow.png';
import DropBlack from './Images/down-arrow-black.png'
import tel from './Images/telephone.png'
import mail from './Images/email.png'
import loc from './Images/broche-de-localisation.png'
import { Modal } from 'flowbite-react'
import './header.css'

function Header() {
    const [openModal, setOpenModal] = useState(false);
    const [modalPlacement, setModalPlacement] = useState('center')
    const emailAddress = 'contact@adpme.bj';

    const handleEmailLinkClick = () => {
        // // Construire l'URL de l'email avec le destinataire prérempli
        // const emailUrl = `mailto:${emailAddress}`;

        // // Ouvrir l'URL dans une nouvelle fenêtre ou un nouvel onglet
        // window.open(emailUrl, '_blank');
    };
    useEffect(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
    })
    return (

        <header className="sticky top-0 w-full z-50">
            <Modal show={openModal} size="5xl" popup onClose={() => setOpenModal(false)} position={modalPlacement}>
                <Modal.Header>Menu</Modal.Header>
                <Modal.Body>
                    <div className="font-[' Montserrat'] items-center justify-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-12 lg:mt-0 space-y-6">
                            <li>
                                <a href="/quisommesnous" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">A propos</a>
                            </li>
                            <li>
                                <a href="/actualites" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">Actualités</a>
                            </li>
                            <li>
                                <a href="/guide" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">Guides</a>
                            </li>
                            <li>
                                <a href="/service" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">Services</a>
                            </li>
                            <li>
                                <a href="/recrutement" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">Opportunités</a>
                            </li>
                            <li>
                                <a href="/contact" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-black dark:hover:bg-gray-700 dark:hover:text-black lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="flex font-[' Montserrat'] justify-end lg:bg-white lg:w-full lg:py-2 lg:block max-sm:hidden max-xl:hidden">
                <div>
                    <ul className="flex w-100 justify-center space-x-3">
                        <li>
                            <a target='_blank' href="https://www.facebook.com/adpmebenin?mibextid=ViGcVu" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.linkedin.com/company/adpme-benin/" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256"
                                    style={{ fill: "#737373" }}>
                                    <g fill="#737373" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" style={{ mixBlendMode: "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path></g></g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.youtube.com/@adpmebenin" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5 hover:text-text-gray-900" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 16 16" style={{ fill: "#737373" }}>
                                    <path d="M 8 2 C 5.960938 2 4.15625 2.210938 3.058594 2.375 C 2.160156 2.507813 1.445313 3.210938 1.28125 4.113281 C 1.140625 4.902344 1 6.054688 1 7.5 C 1 8.945313 1.140625 10.097656 1.28125 10.886719 C 1.445313 11.789063 2.160156 12.488281 3.058594 12.625 C 4.160156 12.789063 5.972656 13 8 13 C 10.027344 13 11.835938 12.789063 12.9375 12.625 L 12.941406 12.625 C 13.839844 12.492188 14.554688 11.789063 14.71875 10.886719 C 14.859375 10.09375 15 8.941406 15 7.5 C 15 6.054688 14.859375 4.902344 14.714844 4.113281 C 14.554688 3.210938 13.839844 2.507813 12.941406 2.375 C 11.84375 2.210938 10.039063 2 8 2 Z M 8 3 C 9.96875 3 11.730469 3.203125 12.792969 3.363281 C 13.261719 3.433594 13.640625 3.800781 13.730469 4.289063 C 13.863281 5.027344 14 6.121094 14 7.5 C 14 8.878906 13.863281 9.972656 13.734375 10.707031 C 13.644531 11.199219 13.265625 11.566406 12.792969 11.636719 C 11.722656 11.792969 9.957031 12 8 12 C 6.042969 12 4.273438 11.792969 3.207031 11.636719 C 2.738281 11.566406 2.355469 11.199219 2.265625 10.707031 C 2.136719 9.96875 2 8.878906 2 7.5 C 2 6.117188 2.136719 5.027344 2.265625 4.289063 C 2.355469 3.800781 2.734375 3.433594 3.203125 3.367188 L 3.207031 3.367188 C 4.269531 3.207031 6.03125 3 8 3 Z M 6 5 L 6 10 L 11 7.5 Z"></path>
                                </svg>
                            </a>
                        </li>

                        &nbsp;&nbsp;&nbsp;
                        <li>
                            <a href={`tel:+22941292722`} className="text-sm text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <div className='inline-flex'>
                                    <svg
                                        className="hovered-paths"
                                        width={21}
                                        height={21}
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 482.6 482.6"
                                        xmlSpace="preserve"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            className="hovered-path"
                                            d="M98.339 320.8c47.6 56.9 104.9 101.7 170.3 133.4 24.9 11.8 58.2 25.8 95.3 28.2 2.3.1 4.5.2 6.8.2 24.9 0 44.9-8.6 61.2-26.3.1-.1.3-.3.4-.5 5.8-7 12.4-13.3 19.3-20 4.7-4.5 9.5-9.2 14.1-14 21.3-22.2 21.3-50.4-.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2-12.8 0-25.1 5.6-35.6 16.1l-35.8 35.8c-3.3-1.9-6.7-3.6-9.9-5.2-4-2-7.7-3.9-11-6-32.6-20.7-62.2-47.7-90.5-82.4-14.3-18.1-23.9-33.3-30.6-48.8 9.4-8.5 18.2-17.4 26.7-26.1 3-3.1 6.1-6.2 9.2-9.3 10.8-10.8 16.6-23.3 16.6-36s-5.7-25.2-16.6-36l-29.8-29.8c-3.5-3.5-6.8-6.9-10.2-10.4-6.6-6.8-13.5-13.8-20.3-20.1-10.3-10.1-22.4-15.4-35.2-15.4-12.7 0-24.9 5.3-35.6 15.5l-37.4 37.4c-13.6 13.6-21.3 30.1-22.9 49.2-1.9 23.9 2.5 49.3 13.9 80 17.5 47.5 43.9 91.6 83.1 138.7zm-72.6-216.6c1.2-13.3 6.3-24.4 15.9-34l37.2-37.2c5.8-5.6 12.2-8.5 18.4-8.5 6.1 0 12.3 2.9 18 8.7 6.7 6.2 13 12.7 19.8 19.6 3.4 3.5 6.9 7 10.4 10.6l29.8 29.8c6.2 6.2 9.4 12.5 9.4 18.7s-3.2 12.5-9.4 18.7c-3.1 3.1-6.2 6.3-9.3 9.4-9.3 9.4-18 18.3-27.6 26.8l-.5.5c-8.3 8.3-7 16.2-5 22.2.1.3.2.5.3.8 7.7 18.5 18.4 36.1 35.1 57.1 30 37 61.6 65.7 96.4 87.8 4.3 2.8 8.9 5 13.2 7.2 4 2 7.7 3.9 11 6 .4.2.7.4 1.1.6 3.3 1.7 6.5 2.5 9.7 2.5 8 0 13.2-5.1 14.9-6.8l37.4-37.4c5.8-5.8 12.1-8.9 18.3-8.9 7.6 0 13.8 4.7 17.7 8.9l60.3 60.2c12 12 11.9 25-.3 37.7-4.2 4.5-8.6 8.8-13.3 13.3-7 6.8-14.3 13.8-20.9 21.7-11.5 12.4-25.2 18.2-42.9 18.2-1.7 0-3.5-.1-5.2-.2-32.8-2.1-63.3-14.9-86.2-25.8-62.2-30.1-116.8-72.8-162.1-127-37.3-44.9-62.4-86.7-79-131.5-10.3-27.5-14.2-49.6-12.6-69.7z"
                                            data-original="#000000"
                                        />
                                    </svg>&nbsp;
                                    <span className='text-sm'>: +229 41 29 27 22</span>
                                </div>
                            </a>
                        </li>
                        &nbsp;&nbsp;&nbsp;

                        <li>
                            <a href={`mailto:${emailAddress}`} onClick={handleEmailLinkClick} className="text-sm text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <div className='inline-flex'>
                                    <svg width={21} height={21} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000"><path fillRule="evenodd" clipRule="evenodd" d="M1 3.5l.5-.5h13l.5.5v9l-.5.5h-13l-.5-.5v-9zm1 1.035V12h12V4.536L8.31 8.9H7.7L2 4.535zM13.03 4H2.97L8 7.869 13.03 4z" /></svg>&nbsp;
                                    <span className='text-sm'>: {emailAddress}</span>
                                </div>
                            </a>
                        </li>
                        &nbsp;&nbsp;&nbsp;
                        <li>
                            <a href="#" className="text-sm text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <div className='inline-flex'>
                                    <svg
                                        width={21} height={21}
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 217.111 217.111"
                                        style={{
                                            enableBackground: "new 0 0 217.111 217.111",
                                        }}
                                        xmlSpace="preserve"
                                        
                                    >
                                        <g>
                                            <path d="M108.552,0C62.422,0,24.891,37.536,24.891,83.674c0,32.967,13.802,66.314,39.913,96.438 c19.503,22.499,38.831,35.246,39.644,35.778c1.247,0.815,2.675,1.222,4.104,1.222c1.428,0,2.857-0.407,4.104-1.222 c0.813-0.532,20.143-13.279,39.647-35.777c26.114-30.124,39.917-63.471,39.917-96.438C192.22,37.536,154.687,0,108.552,0z  M108.552,200.402c-15.387-11.466-68.661-55.594-68.661-116.728C39.891,45.807,70.693,15,108.552,15 c37.864,0,68.668,30.807,68.668,68.674C177.22,144.808,123.942,188.936,108.552,200.402z" />
                                            <path d="M114.81,54.628c-2.93-2.929-7.678-2.928-10.607,0c-2.929,2.929-2.928,7.678,0.001,10.607l10.873,10.872H84.174 c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h30.902l-10.971,10.97c-2.929,2.929-2.929,7.678,0,10.607 c1.465,1.464,3.384,2.197,5.303,2.197c1.92,0,3.839-0.732,5.304-2.196l23.775-23.773c1.407-1.407,2.197-3.314,2.197-5.304 c0-1.989-0.79-3.897-2.197-5.304L114.81,54.628z" />
                                        </g>
                                    </svg>
                                    &nbsp;
                                    <span className='text-sm'>: Immeuble R+5 C/1184 Cadjèhoun 01 BP 8435 Cotonou</span>
                                </div>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
            <nav className="bg-[#E3F2E5] border-gray-200 py-2.5 dark:bg-gray-900 font-[' Montserrat']">
                <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
                    <a href="/accueil" className="flex items-center">
                        <img src={logo} className="md:w-16 lg:w-64 max-md:w-64" alt="ADPME Logo" />
                    </a>
                    <div className="flex items-center lg:order-2" onClick={() => setOpenModal(true)}>
                        <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-12 lg:mt-0">
                            <li>
                                <a href="/quisommesnous" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">A propos</a>
                            </li>
                            <li>
                                <a href="/actualites" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Actualités</a>
                            </li>
                            {/* <li>
                                <Dropdown className="flex items-center justify-between w-full py-2 px-3 text-white rounded md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white dark:focus:text-white dark:border-gray-700" label="" dismissOnClick={false} renderTrigger={() => <span className='text-black cursor-pointer' style={{ display: 'flex', flexDirection: 'row' }}>Actualités<img className='h-5 w-5' src={DropBlack} /></span>} inline>
                                    <Dropdown.Item as="a" href="/event" className='hover:bg-gray-100 lg:hover:text-amber-400'>Evènements</Dropdown.Item>
                                    <Dropdown.Item as="a" href="/communique" className='hover:bg-gray-100 lg:hover:text-amber-400'>Communiqués</Dropdown.Item>
                                    <Dropdown.Item as="a" href="/projet" className='hover:bg-gray-100 lg:hover:text-amber-400'>Projets</Dropdown.Item>
                                </Dropdown>

                            </li> */}
                            <li>
                                <a href="/guide" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Guides</a>
                            </li>
                            <li>
                                <a href="/service" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Services</a>
                            </li>
                            {/* <li>
                                <Dropdown className="flex items-center justify-between w-full py-2 px-3 text-white rounded md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white dark:focus:text-white dark:border-gray-700" label="" dismissOnClick={false} renderTrigger={() => <span className='text-black cursor-pointer' style={{ display: 'flex', flexDirection: 'row' }}>Services<img className='h-5 w-5' src={DropBlack} /></span>} inline>
                                    <Dropdown.Item as="a" href="/aide" className='hover:bg-gray-100 lg:hover:text-amber-400'>Besoin d'aide ?</Dropdown.Item>
                                    <Dropdown.Item as="a" href="/eservice" className='hover:bg-gray-100 lg:hover:text-amber-400'>E-services</Dropdown.Item>
                                    <Dropdown.Item as="a" href="/service" className='hover:bg-gray-100 lg:hover:text-amber-400'>Autres services</Dropdown.Item>
                                </Dropdown>
                            </li> */}
                            <li>
                                <a href="/recrutement" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Opportunités</a>
                            </li>
                            <li>
                                <a href="/contact" className="text-black hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-amber-400 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header >
    );
}

export default Header;