import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

function Presentation(props) {
    const greenTextRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(greenTextRef.current,
            { text: "" },
            {
                text: "de fédérer et de mettre en cohérence l'ensemble des interventions publiques en appui aux MPME avec une offre intégrée d'accompagnement, d'orientation et de financement des MPME",
                duration: 3,
                ease: "none",
                delay: 0.5,
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: "top 80%", // Déclenche lorsque le haut de la section atteint 80% de la hauteur de la fenêtre
                    toggleActions: "play none none none", // Joue l'animation lors du scroll
                }
            });
    }, []);

    return (
        <>
            <section ref={sectionRef} className="bg-white dark:bg-gray-900 max-md:px-4 p-28">
                <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                    <div className='flex text-left'>
                        <div>
                            <h2 className="text-neutral-900 max-md:text-[25px] text-[32px] font-semibold font-['Montserrat']">Présentation de l'ADPME</h2>
                        </div>
                    </div>
                    <div>
                        <div className="text-[24px] max-md:text-[17px] font-['Montserrat']">
                            <p className="text-justify font-['Montserrat']">
                                L'Agence de Développement des Petites et Moyennes Entreprises
                                a pour vocation <span className='text-green-700 font-bold' ref={greenTextRef}></span>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Presentation;
