import React,{useState, useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import GuideViewer from '../GuideViewer/GuideViewer';
import { Modal } from 'flowbite-react'
import gsap from 'gsap';
// Core viewer
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const urlstandart = process.env.REACT_APP_URL_STANDART

function GuideCard(props) {
    // Create new plugin instance
    let {id} = useParams()
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [openModal, setOpenModal] = useState(false);
    const cardRef = React.useRef(null);
    const nav = useNavigate();

    useEffect(() => {
        if (parseInt(id) === parseInt(props.id)) {
            setOpenModal(true);
        }
    }, [parseInt(id), parseInt(props.id)]);

    React.useEffect(() => {
        gsap.from(cardRef.current, {
            opacity: 0,
            y: 20,
            duration: 0.5,
            ease: "power2.out"
        });
    }, []);

    const closeModal = () => {
        setOpenModal(false);
        if(props.mode)
        {
            nav('/accueil');
        }else{
            nav('/guide');
        }
    }
    return (
        <>
            <div className="max-w-sm font-['Montserrat'] hover:bg-green-400 text-neutral-800 hover:text-white shadow-sm h-[260px] bg-green-100 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                <div className="p-5">
                    {props.img ? (<img crossOrigin="anonymous" src={props.img} className="w-7 h-7 text-gray-500 dark:text-gray-400 mb-3" />) : ""}
                    <div className='pt-5'>
                        <a onClick={() => setOpenModal(true)}>
                            <Link to={`${props.id}`}>
                                <h5 className="cursor-pointer mb-2 text-2xl font-semibold tracking-tight dark:text-white line-clamp-3">{props.title}</h5>
                            </Link>
                        </a>
                    </div>
                    <div className='flex flex-col text-left pt-2'>
                        <Link to={`${props.id}`}>
                            <a className='flex flex-row text-left items-center pb-1 font-bold text-green-700 hover:underline cursor-pointer' onClick={() => setOpenModal(true)}>
                                <span>Voir notre guide</span>
                                <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                                </svg>
                            </a>
                        </Link>
                        <div>
                            <span>Source ADPME</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <GuideViewer /> */}
            {id && (
                <Modal show={openModal} size="7xl" popup onClose={closeModal} position="center">
                    <Modal.Header>Document Viewer</Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%', height: '100%' }}>
                            {/* <Document file={`${urlstandart}api/${resinfo}`} style={{ width: '100%', height: '100%' }}>
                                <Page pageNumber={1} scale={1.5} />
                            </Document> */}
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={`${urlstandart}api/${props.doc}`}
                                    plugins={[
                                        // Register plugins
                                        defaultLayoutPluginInstance
                                    ]}
                                />
                            </Worker>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default GuideCard;