import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import axios from "axios";
import { Modal } from "flowbite-react";
import ReCAPTCHA from "react-google-recaptcha";
import plainteimg from "./Images/Plainte.PNG";

export default function AppelsOffresCard(props) {
  let imginfo = props.notice;
  let tabimg = [];
  tabimg = imginfo.split(",");
  imginfo = tabimg[0];
  imginfo = imginfo.replace(/\\/g, "/");

  const today = new Date();
  const eventDate = new Date(props.endDate);

  const isDatePassed = today > eventDate;

  const [formData, setFormData] = useState({
    demandeurNom: "",
    demandeurPrenom: "",
    demandeurQualite: "",
    entrepriseNom: "",
    entrepriseAdresse: "",
    entrepriseTelephone: "",
    entrepriseEmail: "",
  });
  const [demandeurNom, setDemandeurNom] = useState("");
  const [demandeurPrenom, setDemandeurPrenom] = useState("");
  const [demandeurQualite, setDemandeurQualite] = useState("");
  const [entrepriseNom, setEntrepriseNom] = useState("");
  const [entrepriseAdresse, setEntrepriseAdresse] = useState("");
  const [entrepriseTelephone, setEntrepriseTelephone] = useState("");
  const [entrepriseEmail, setEntrepriseEmail] = useState("");

  const [recaptchaRef, setRecaptchaRef] = useState(null);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [verified, setVerified] = useState(false);

  const formatDateTimeLocal = (dateString) => {
    const date = new Date(dateString);
    // console.log(date)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours());
    const minutes = String(date.getMinutes());
    const seconds = String(date.getSeconds());

    const heure = new Date(dateString).toISOString().split("T")[1];
    // .originalString.slice(8)
    const h = heure.slice(0, 8);
    // console.log(typeof heure)
    return `${year}-${month}-${day} à ${h}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function onChangeRecaptcha(value) {
    setVerified(!verified);
  }

  const ModalClose = () => {
    setIsModalShow(false);
    // window.location.reload()
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      demandeurNom === "" ||
      demandeurPrenom === "" ||
      demandeurQualite === "" ||
      entrepriseAdresse === "" ||
      entrepriseEmail === "" ||
      entrepriseTelephone === "" ||
      entrepriseNom === ""
    ) {
      alert("Veuillez renseigner tous les champs");
    } else {
      if (
        window.confirm(
          `Voulez-vous confirmer la soumission de ce formulaire ? \n Nous tenons à vous préciser que vous recevrez un mail avec le lien pour télécharger le dossier d'Appel d'offre sur l'email ${entrepriseEmail} que vous avez renseigné sur ce formulaire.`
        )
      ) {
        console.log("Form Data:", formData);
        setIsClicked(true);
        const requesterCallOffers = [
          {
            firstname: demandeurPrenom,
            lastname: demandeurNom,
            quality: demandeurQualite,
            callOfferId: props.id,
          },
        ];
        const newurl = process.env.REACT_APP_URL_STANDART + "api/candidates";
        axios
          .post(
            newurl,
            {
              name: entrepriseNom,
              address: entrepriseAdresse,
              email: entrepriseEmail,
              phone: entrepriseTelephone,
              requesterCallOffers: JSON.stringify(requesterCallOffers),
            },
            { withCredentials: true }
          )
          .then((response) => {
            // recaptchaRef.current.reset();
            setDemandeurNom("");
            setDemandeurPrenom("");
            setDemandeurQualite("");
            setEntrepriseNom("");
            setEntrepriseAdresse("");
            setEntrepriseTelephone("");
            setEntrepriseEmail("");
            setIsModalShow(true);
            setIsClicked(false);
            setRecaptchaRef(null);
            console.log(response);
          })
          .catch((err) => {
            setIsClicked(false);
            alert("Une erreur s'est produite. Si votre entreprise a déjà soumissionné pour cette offre, la re-soumission peut être à l'origine de cette erreur.")
          });
      }
    }
  };

  return (
    <div className="flex max-md:flex max-md:flex-col max-lg:flex max-lg:flex-col">
      <div className="basis-1/4 flex flex-col gap-4 bg-gray-50 p-5">
        <div className="flex gap-4">
          {/* Contient l'icone et la deuxième div pour par ex publié le et la date */}
          <div>
            <svg
              fill="#000000"
              width={21}
              height={21}
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
            </svg>
          </div>
          <div>
            <div className="font-bold">Publié le</div>
            <div>{new Date(props.startDate).toISOString().split("T")[0]}</div>
          </div>
        </div>

        <div className="flex gap-4">
          {/* Contient l'icone et la deuxième div pour par ex Objet et le titre */}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              width={21}
              height={21}
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
              <path d="M22 6l-10 7L2 6" />
            </svg>
          </div>
          <div>
            <div className="font-bold">Objet</div>
            <div>{props.title}</div>
          </div>
        </div>

        <div className="flex gap-4">
          {/* Contient l'icone et la deuxième div pour par ex Objet et le titre */}
          <div>
            <svg
              fill="#000000"
              width={21}
              height={21}
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
            </svg>
          </div>
          <div>
            <div className="font-bold">Date de clôture</div>
            <div>{formatDateTimeLocal(props.endDate)}</div>
          </div>
        </div>
      </div>
      <div className="basis-1/2 p-5 flex flex-col gap-4">
        {/* Titre */}
        <div className="font-bold text-xl">{props.title}</div>
        {/* Type d'appel d'offre */}
        <div className="text-amber-700 font-normal">{props.type}</div>
        {/* Description */}
        <div className="line-clamp-3">{props.description}</div>
        <div className="flex gap-4">
          <a
            href={process.env.REACT_APP_URL_STANDART + "api/" + imginfo}
            target="_blank"
          >
            <Button
              variant="gradient"
              className="rounded-none text-white hover:text-green-700 hover:border hover:border-green-700 hover:bg-white bg-green-700 flex items-center gap-2 justify-center text-center"
            >
              Détail de l'offre
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Button>
          </a>
        </div>
      </div>
      <div className="basis-1/2 border-l p-2">
        <div className="font-bold text-lg">Formulaire de demande</div>
        <form onSubmit={handleSubmit}>
          {/* <div className="font-normal text-sm">Informations concernant le demandeur</div> */}
          <fieldset className="border border-solid border-gray-300 p-3">
            <legend>
              <div className="font-normal text-sm">
                Informations concernant le demandeur
              </div>
            </legend>
            <div className="flex gap-2 max-md:flex max-md:flex-col max-lg:flex max-lg:flex-col">
              <div className="basis-1/2">
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Nom(s)
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="demandeurNom"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 smxs sm:leading-6"
                    value={demandeurNom}
                    onChange={(e) => setDemandeurNom(e.target.value)}
                  />
                </div>
              </div>
              <div className="basis-1/2">
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Prénom(s)
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="demandeurPrenom"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    value={demandeurPrenom}
                    onChange={(e) => setDemandeurPrenom(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2 max-md:flex max-md:flex-col max-lg:flex max-lg:flex-col">
              <div className="basis-1/2">
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Qualité
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="demandeurQualite"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    value={demandeurQualite}
                    onChange={(e) => setDemandeurQualite(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="border border-solid border-gray-300 p-3">
            <legend>
              <div className="font-normal text-sm">
                Informations concernant l'entreprise
              </div>
            </legend>
            <div className="basis-1/2">
              <label
                htmlFor="first-name"
                className="block text-xs font-medium leading-6 text-gray-900"
              >
                Nom / Raison sociale
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="entrepriseNom"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  value={entrepriseNom}
                  onChange={(e) => setEntrepriseNom(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Adresse physique
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="entrepriseAdresse"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    value={entrepriseAdresse}
                    onChange={(e) => setEntrepriseAdresse(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2 max-md:flex max-md:flex-col max-lg:flex max-lg:flex-col">
              <div className="basis-1/2">
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Adresse mail
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="entrepriseEmail"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    value={entrepriseEmail}
                    onChange={(e) => setEntrepriseEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="basis-1/2">
                <label
                  htmlFor="first-name"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Téléphone
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="entrepriseTelephone"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    value={entrepriseTelephone}
                    onChange={(e) => setEntrepriseTelephone(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="font-semibold text-xs text-red-600 pt-1">* Vous recevrez le dossier sur l'email que vous allez renseigner sur ce formulaire</div>
          </fieldset>

          <div className="mb-3 mt-3">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
              onChange={onChangeRecaptcha}
            />
          </div>
          <div className="pt-2">
            {isDatePassed ? (
              <>
                <Button
                  disabled={false}
                  type="submit"
                  variant="gradient"
                  className="rounded-none text-white hover:text-green-700 hover:border hover:border-green-700 hover:bg-white bg-green-700 flex items-center gap-2 justify-center text-center"
                >
                  Faire la demande
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={false}
                  type="submit"
                  variant="gradient"
                  className="rounded-none text-white hover:text-green-700 hover:border hover:border-green-700 hover:bg-white bg-green-700 flex items-center gap-2 justify-center text-center"
                >
                  Demande d'offre
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
      {/* Modal success */}
      <Modal
        show={isModalShow}
        size="2xl"
        popup
        onClose={() => setIsModalShow(false)}
        position="center"
      >
        {/* <Modal.Header>Document Viewer</Modal.Header> */}
        <Modal.Body>
          <div className="flex flex-row gap-5 px-10 pt-16 max-md:px-4 max-md:pt-4 max-md:flex max-md:flex-col">
            <div className="text-center flex justify-center items-center">
              <img
                className="w-full max-md:w-[50%] max-md:h-[50%]"
                src={plainteimg}
                alt=""
              />
            </div>
            <div className="text-center justify-center items-center flex flex-col">
              <div className="font-['Montserrat'] mb-3 font-bold text-3xl max-md:text-xl">
                Demande soumise avec succès
              </div>
              <div className="font-['Montserrat'] mb-3 font-normal text-3sm">
                Votre demande a été prise en compte ! Vous recevrez le dossier
                dans le mail indiqué.
              </div>
              <div>
                <Button
                  onClick={ModalClose}
                  variant="gradient"
                  className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700"
                >
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
