import React, {useRef, useEffect} from 'react';
import img from '../Images/97aa576264f4bb462bdc4925813690dc.jpg'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

function DescBannniereApropos(props) {
    const titleRef = useRef(null);

    useEffect(() => {
        if (!titleRef.current) return; // Vérifie que les références sont définies

        // Animation des textes
        const tlText = gsap.timeline({
            repeat: -1, // Répétition infinie
            yoyo: true, // Animation dans les deux sens
            defaults: { ease: "none" }
        });

        tlText.to(titleRef.current, {
            duration: 2,
            text: "En savoir plus sur l'ADPME",
            delay: 1,
            ease: "power2.out",
        });

    }, []);

    return (
        <>
            <div className="flex flex-col font-[' Montserrat'] justify-center">
                <div className='px-32 max-md:px-4 py-10 bg-white'>
                    <div>
                        <span className="text-yellow-300  max-md:text-[16px] text-[24px] font-bold font-['Montserrat']" ref={titleRef}>Qui sommes-nous ?</span>
                    </div>
                    <div>
                        <span className="text-neutral-900 max-md:text-[20px] text-[32px] font-bold font-['Montserrat']">
                            Votre accompagnateur dès votre naissance et à toutes les phases de votre développement
                        </span>
                    </div>
                </div>
                <div>
                    <img className='w-full' src={img} />
                </div>
            </div>
        </>
    );
}

export default DescBannniereApropos;