import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import fondvert from '../Images/fondlogovert.jpg';

gsap.registerPlugin(ScrollTrigger);

function Missions() {
    const missionItemsRef = useRef([]);

    useEffect(() => {
        const items = missionItemsRef.current;
        
        gsap.fromTo(items, 
            { opacity: 0, y: 50 }, 
            { 
                opacity: 1, 
                y: 0, 
                duration: 0.6, 
                ease: "power2.out", 
                stagger: 0.2,
                scrollTrigger: {
                    trigger: items,
                    start: "top 80%",
                    end: "bottom 20%",
                    toggleActions: "play none none none",
                }
            }
        );
    }, []);

    return (
        <section style={{ backgroundImage: `url(${fondvert})` }} className="bg-white bg-no-repeat dark:bg-gray-900 max-md:px-4 p-28">
            <div className="grid grid-cols-2 max-xl:flex max-xl:flex-col">
                <div className="flex text-left">
                    <h2 className="text-neutral-900 max-md:text-[25px] text-[32px] font-semibold font-['Montserrat']">
                        Quelles sont nos missions ?
                    </h2>
                </div>
                <div className="text-[24px] max-md:text-[17px] font-['Montserrat']">
                    <p className="mb-4 text-justify font-['Montserrat']">
                        Contribuer à la promotion et au développement des micros, petites et moyennes entreprises du Bénin à travers l'orientation, l'accompagnement, le financement et la facilitation de l'accès aux marchés.
                        <br />À ce titre, elle est chargée de :
                    </p>
                    <div className="text-[17px] grid grid-cols-2 gap-4 mt-8 max-xl:flex max-xl:flex-col">
                        <div>
                            <ul className="text-black list-inside dark:text-black max-xl:space-y-6">
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[0] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Accueillir, informer et orienter les micros, petites et moyennes entreprises vers les différents dispositifs d’appuis existants
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[1] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Instruire et agréer au régime de micros, petites et moyennes entreprises
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[2] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Aider les micros, petites et moyennes entreprises à saisir les opportunités au niveau local, sous-régional et international
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[3] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Conseiller, encadrer et appuyer le développement des micros, petites et moyennes entreprises
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="text-black list-inside dark:text-black max-xl:space-y-6">
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[4] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Identifier et accompagner les entreprises stratégiques ou à fort potentiel de croissance
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[5] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Développer un système d’information sur les micros, petites et moyennes entreprises
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[6] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Organiser la participation des micros, petites et moyennes entreprises aux foires et manifestations commerciales
                                </li>
                                <li 
                                    className="flex items-center mb-5 font-['Montserrat']" 
                                    ref={(el) => missionItemsRef.current[7] = el}
                                >
                                    <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" 
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    Développer un système d’information sur les micros, petites et moyennes entreprises
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Missions;
<ul className='max-xl:space-y-6 text-black'>
                                            <li className="flex items-center mb-5 font-['Montserrat']">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Identifier et accompagner les entreprises stratégiques ou à fort potentiel de croissance ;
                                            </li>
                                            <li className="flex items-center mb-5 font-['Montserrat']">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Faciliter l’accès des micros, petites et moyennes entreprises au financement et aux marchés ;
                                            </li>
                                            <li className="flex items-center mb-5 font-['Montserrat']">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Promouvoir des outils et produits financiers innovants et adaptés aux besoins des micros, petites et moyennes entreprises ;
                                            </li>
                                            <li className="flex items-center mb-5 font-['Montserrat']">
                                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                Aider à la restructuration et à la mise à niveau des entreprises en difficulté.
                                            </li>
                                        </ul>